import AccountCircle from "@mui/icons-material/AccountCircle";
import { ListItemButton, Divider } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import CrescendoForm from "../pages/forms/crescendoForm";
import ReefscapeForm from "../pages/forms/reefscapeForm";
import DataCollector from "./datacollector";
import iconImage from "../images/torch.png";
import MenuIcon from "@mui/icons-material/Menu";
import Home from "./home";
import Analysis from "./analysis";
import Leaderboard from "./leaderboard";
import Account from "./account";

async function GetAllianceData() {
  const data = await fetch(`/api/blueAlliance`);
  let parsed = data.json();
  return parsed;
}

export default function Dashboard({ signOut, user }) {
  const [auth, setAuth] = React.useState(true);
  const [robotSide, setRobotSide] = React.useState(undefined);
  const [anchorElComputer, setAnchorElComputer] = React.useState(null);
  const [anchorElMobile, setAnchorElMobile] = React.useState(null);
  const [menuIndex, setMenuIndex] = React.useState(1);

  useEffect(() => {
    const fetchData = async () => {
      const result = await GetAllianceData();
      console.log(JSON.parse(result));
    };
    fetchData();
  }, []);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleComputerMenu = (event) => {
    setAnchorElComputer(event.currentTarget);
  };

  const handleMobileMenu = (event) => {
    setAnchorElMobile(event.currentTarget);
  };

  const handleComputerClose = () => {
    setAnchorElComputer(null);
  };
  const handleMobileClose = () => {
    setAnchorElMobile(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#110000",
          borderBottom: "2px solid #666",
        }}
      >
        <Toolbar>
          <Box sx={{ display: { xs: "none", sm: "contents" } }}>
            <img
              src={iconImage}
              style={{
                width: 30,
                height: 30,
                zIndex: "999",
                marginRight: 5,
              }}
            />
            <Typography
              variant="h6"
              component="div"
              sx={{
                marginRight: 1,
                color: "#ffe8c0",
                fontWeight: "bold",
              }}
            >
              Ignite Insights
            </Typography>
            <Link to={"home"} style={{ textDecoration: "none", color: "#fff" }}>
              <ListItemButton
                onClick={() => setMenuIndex(1)}
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  margin: 0.3,
                  flexGrow: "1",
                  backgroundColor: menuIndex === 1 ? "#090000" : "transparent",
                  border: menuIndex === 1 ? "1px solid" : "",
                  borderColor: "#ffe8c0",
                  borderRadius: "3",
                  color: "#ffe8c0",
                }}
              >
                <Typography component="div" style={{ fontSize: 15 }}>
                  <b>Home</b>
                </Typography>
              </ListItemButton>
            </Link>
            <Link
              to={"analysis"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <ListItemButton
                onClick={() => setMenuIndex(2)}
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  margin: 0.3,
                  flexGrow: "1",
                  backgroundColor: menuIndex === 2 ? "#090000" : "transparent",
                  border: menuIndex === 2 ? "1px solid" : "",
                  borderColor: "#ffe8c0",
                  borderRadius: "3",
                  color: "#ffe8c0",
                }}
              >
                <Typography component="div" style={{ fontSize: 15 }}>
                  <b>Analysis</b>
                </Typography>
              </ListItemButton>
            </Link>
            <Link
              to={"leaderboard"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <ListItemButton
                onClick={() => setMenuIndex(3)}
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  margin: 0.3,
                  flexGrow: "1",
                  backgroundColor: menuIndex === 3 ? "#090000" : "transparent",
                  border: menuIndex === 3 ? "1px solid" : "",
                  borderColor: "#ffe8c0",
                  borderRadius: "3",
                  color: "#ffe8c0",
                }}
              >
                <Typography component="div" style={{ fontSize: 15 }}>
                  <b>Leaderboard</b>
                </Typography>
              </ListItemButton>
            </Link>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: "1" }}
            ></Typography>
            <Link
              to={"datacollector"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <ListItemButton
                onClick={() => setMenuIndex(4)}
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  margin: 0.3,
                  backgroundColor: "#090000",
                  border: "1px solid",
                  borderColor: "#ffe8c0",
                  borderRadius: "3",
                  color: "#ffe8c0",
                  textAlign: "center",
                }}
              >
                <Typography component="div" style={{ fontSize: 15 }}>
                  <b>Collect Data</b>
                </Typography>
              </ListItemButton>
            </Link>
            {auth && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleComputerMenu}
                  sx={{ color: "#ffe8c0" }}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="computerIcon"
                  anchorEl={anchorElComputer}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElComputer)}
                  onClose={handleComputerClose}
                >
                  <Link
                    to={"account"}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <MenuItem onClick={handleComputerClose}>
                      My Account
                    </MenuItem>
                  </Link>
                  <MenuItem onClick={signOut}>Sign Out</MenuItem>
                </Menu>
              </div>
            )}
          </Box>
          <Box sx={{ display: { xs: "contents", sm: "none" } }}>
            <img
              src={iconImage}
              style={{
                width: 30,
                height: 30,
                zIndex: "999",
                marginRight: 11,
              }}
            />
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: "1" }}
            ></Typography>
            <Link
              to={"datacollector"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <ListItemButton
                onClick={() => setMenuIndex(4)}
                fullWidth
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  margin: 1,
                  backgroundColor: "#090000",
                  border: "1px solid",
                  borderColor: "#ffe8c0",
                  borderRadius: "3",
                  color: "#ffe8c0",
                  textAlign: "center",
                }}
              >
                <Typography component="div" style={{ fontSize: 15 }}>
                  <b>Collect Data</b>
                </Typography>
              </ListItemButton>
            </Link>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: "1" }}
            ></Typography>
            {auth && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMobileMenu}
                  sx={{ color: "#ffe8c0", width: 30 }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="mobileIcon"
                  anchorEl={anchorElMobile}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElMobile)}
                  onClose={handleMobileClose}
                >
                  <Link
                    to={"account"}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <MenuItem onClick={handleMobileClose}>My Account</MenuItem>
                  </Link>
                  <Divider />
                  <Link
                    to={"home"}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <MenuItem onClick={handleMobileClose}>Home</MenuItem>
                  </Link>
                  <Link
                    to={"analysis"}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <MenuItem onClick={handleMobileClose}>Analysis</MenuItem>
                  </Link>
                  <Link
                    to={"leaderboard"}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <MenuItem onClick={handleMobileClose}>Leaderboard</MenuItem>
                  </Link>
                  <Divider />
                  <MenuItem onClick={signOut}>Sign Out</MenuItem>
                </Menu>
              </div>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          backgroundColor: "background.main",
          minHeight: "90vh",
          maxHeight: "max-content",
          position: "relative",
        }}
      >
        <Box>
          <Routes>
            <Route path="/" element={<Home user={user} signOut={signOut} />} />
            <Route
              path="home"
              element={<Home user={user} signOut={signOut} />}
            />
            <Route
              path="analysis"
              element={<Analysis user={user} signOut={signOut} />}
            />
            <Route
              path="leaderboard"
              element={<Leaderboard user={user} signOut={signOut} />}
            />
            <Route path="/datacollector" element={<DataCollector />}>
              <Route
                path="reefscape"
                element={
                  <>
                    <ReefscapeForm />
                  </>
                }
              />
              <Route
                path="crescendo"
                element={
                  <>
                    <CrescendoForm />
                  </>
                }
              />
            </Route>
            <Route
              path="account"
              element={<Account user={user} signOut={signOut} />}
            />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}
