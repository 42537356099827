import { Grid, Typography, Box } from "@mui/material";
import * as React from "react";

export default function Home({ user, signOut }) {
  return (
    <Box>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Typography>THIS IS HOME</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
