//Imports
//Components
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  IconButton,
  Typography,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
//Icons
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { ThemeContext } from "../themes/themes";
import { useFormData } from "../context/FormContext";
import { Outlet, useNavigate } from "react-router-dom";
import { fetchEvents, fetchRobotFromMatch } from "../api/apis";

//Div Definition
const Div = styled("div", {
  name: "MuiDiv",
  overridesResolver: (props, styles) => {
    return [styles.root];
  },
})();

//Actual Tab
export default function DataCollector(signOut, user) {
  //VARIABLES
  const { formData, updateFormData } = useFormData();
  const currentYear = new Date().getFullYear();
  const [eventYear, setEventYear] = useState(currentYear);
  const { toggleTheme } = useContext(ThemeContext);
  const [robot, setRobot] = useState("");
  const navigate = useNavigate();
  const eventNames = ["Crescendo", "Reefscape"];
  const themeMap = {
    2024: "crescendo",
    2025: "reefscape",
  };
  const [collectionStyle, setCollectionStyle] = useState("manual");
  const [eventList, setEventList] = useState([]);
  const loadEvents = async (year) => {
    try {
      const events = await fetchEvents(year);
      setEventList(events);
    } catch (error) {
      console.error("Failed to load events", error);
    }
  };

  useEffect(() => {
    loadEvents(eventYear);
    toggleTheme(themeMap[eventYear]);
    navigate("/datacollector/" + themeMap[eventYear]);
  }, []);

  //HandleChanges
  const handleNumberChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: Number(e.target.value),
    });
    console.log(formData);
  };

  const handleStringChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: `${e.target.value}`,
    });
    console.log(formData);
  };

  const handleAutocompleteChange = (e, value) => {
    updateFormData({
      ...formData,
      event: value ? value.value : "",
    });
    console.log(formData);
  };

  const handleYearChange = (newYear) => {
    setEventYear(newYear);
    loadEvents(newYear);
    toggleTheme(themeMap[newYear] || "igniteInsightsDark");
    updateFormData({
      ...formData,
      event: null,
    });
    navigate("/datacollector/" + themeMap[newYear]);
  };

  const handleMatchChange = (e) => {
    handleNumberChange(e);
    handleRobotAPIChange(
      Number(e.target.value),
      formData.event,
      collectionStyle
    );
  };

  const handleEventChange = (e, value) => {
    handleAutocompleteChange(e, value);
    if (value && value.value != null) {
      handleRobotAPIChange(formData.match, value.value, collectionStyle);
    }
  };

  const handleCollectionStyleChangeToggleButtons = (event, newAlignment) => {
    if (newAlignment != null) {
      setCollectionStyle(newAlignment);
      handleRobotAPIChange(formData.match, formData.event, newAlignment);
    }
  };

  const handleCollectionStyleChangeSelect = (e) => {
    console.log(e.target.value);
    if (e.target.value != null) {
      setCollectionStyle(e.target.value);
      handleRobotAPIChange(formData.match, formData.event, e.target.value);
    }
  };

  const handleRobotAPIChange = async (match, eventCode, whichBot) => {
    const robotData = await fetchRobotFromMatch(eventCode, match, whichBot);

    if (robotData) {
      setRobot(robotData.robot);
      updateFormData((prevData) => ({
        ...prevData,
        ...robotData,
      }));
    }
  };

  return (
    <>
      <Box sx={{ display: { xs: "none", sm: "contents" } }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Div
              id="foreground"
              variant="outlined"
              sx={{
                borderRadius: 2,
                border: 1,
                borderColor: "border.main",
                backgroundColor: "foreground.main",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    margin: "0px !important",
                    width: "100% !important",
                  }}
                >
                  <Grid item xs={3}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <IconButton
                        sx={{ color: "primary.light", scale: "130%" }}
                        size="large"
                        onClick={() => {
                          if (eventYear > 2024) {
                            handleYearChange(eventYear - 1);
                          }
                        }}
                      >
                        <KeyboardDoubleArrowLeft fontSize="inherit" />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ width: "100%" }}>
                      <Stack spacing={0}>
                        <Typography
                          variant="h4"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "bold",
                            color: "secondary.main",
                          }}
                        >
                          {eventNames[eventYear - 2024]}
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            color: "secondary.light",
                          }}
                        >
                          {eventYear}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <IconButton
                        sx={{ color: "primary.light", scale: "130%" }}
                        size="large"
                        onClick={() => {
                          if (eventYear < currentYear) {
                            handleYearChange(eventYear + 1);
                          }
                        }}
                      >
                        <KeyboardDoubleArrowRight fontSize="inherit" />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="collectors_team"
                      fullWidth
                      label="Your Team #"
                      variant="outlined"
                      type="number"
                      onChange={handleNumberChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="collectors_name"
                      fullWidth
                      label="Your Name"
                      variant="outlined"
                      onChange={handleStringChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ToggleButtonGroup
                      name="collectionStyle"
                      exclusive
                      value={collectionStyle}
                      aria-label="Collection Style"
                      fullWidth
                      onChange={handleCollectionStyleChangeToggleButtons}
                    >
                      <ToggleButton value="r1" color="red">
                        Red 1
                      </ToggleButton>
                      <ToggleButton value="r2" color="red">
                        Red 2
                      </ToggleButton>
                      <ToggleButton value="r3" color="red">
                        Red 3
                      </ToggleButton>
                      <ToggleButton value="manual" color="gray">
                        Manual
                      </ToggleButton>
                      <ToggleButton value="b1" color="blue">
                        Blue 1
                      </ToggleButton>
                      <ToggleButton value="b2" color="blue">
                        Blue 2
                      </ToggleButton>
                      <ToggleButton value="b3" color="blue">
                        Blue 3
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip
                      title={
                        <React.Fragment>
                          <Typography>Search By...</Typography>
                          {"- Event Name"}
                          <br />
                          {"- Event Code"}
                          <br />
                          {"- Location"}
                        </React.Fragment>
                      }
                      placement="top"
                      arrow
                    >
                      <Autocomplete
                        key={eventYear}
                        name="event"
                        options={eventList}
                        groupBy={(option) => option.week}
                        getOptionKey={(option) => option.value}
                        getOptionLabel={(option) => option.label}
                        filterOptions={(options, { inputValue }) => {
                          const filtered = options.filter(
                            (option) =>
                              option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()) ||
                              option.eventCode
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()) ||
                              option.location
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                          );
                          return filtered;
                        }}
                        onChange={handleEventChange}
                        renderInput={(params) => (
                          <TextField {...params} label="Event" />
                        )}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name="match"
                      fullWidth
                      label="Match"
                      variant="outlined"
                      type="number"
                      onChange={handleMatchChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      value={robot}
                      name="robot"
                      fullWidth
                      label="Robot #"
                      variant="outlined"
                      type="number"
                      InputLabelProps={{ shrink: robot ? true : undefined }}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRobot(newValue);
                        handleNumberChange(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Div>
          </Grid>
        </Grid>
        <Box>
          <Outlet />
        </Box>
      </Box>
      <Box sx={{ display: { xs: "contents", sm: "none" } }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Div
              id="foreground"
              variant="outlined"
              sx={{
                borderRadius: 2,
                border: 1,
                borderColor: "border.main",
                backgroundColor: "foreground.main",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    margin: "0px !important",
                    width: "100% !important",
                  }}
                >
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <IconButton
                        sx={{ color: "primary.light", scale: "100%" }}
                        size="large"
                        onClick={() => {
                          if (eventYear > 2024) {
                            handleYearChange(eventYear - 1);
                          }
                        }}
                      >
                        <KeyboardDoubleArrowLeft fontSize="inherit" />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box sx={{ width: "100%" }}>
                      <Stack spacing={0}>
                        <Typography
                          variant="h4"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "bold",
                            color: "secondary.main",
                          }}
                        >
                          {eventNames[eventYear - 2024]}
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            color: "secondary.light",
                          }}
                        >
                          {eventYear}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <IconButton
                        sx={{ color: "primary.light", scale: "100%" }}
                        size="large"
                        onClick={() => {
                          if (eventYear < currentYear) {
                            handleYearChange(eventYear + 1);
                          }
                        }}
                      >
                        <KeyboardDoubleArrowRight fontSize="inherit" />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="collectors_team"
                      fullWidth
                      label="Your Team #"
                      variant="outlined"
                      type="number"
                      onChange={handleNumberChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="collectors_name"
                      fullWidth
                      label="Your Name"
                      variant="outlined"
                      onChange={handleStringChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip
                      title={
                        <React.Fragment>
                          <Typography>Search By...</Typography>
                          {"- Event Name"}
                          <br />
                          {"- Event Code"}
                          <br />
                          {"- Location"}
                        </React.Fragment>
                      }
                      placement="bottom"
                      arrow
                    >
                      <Autocomplete
                        key={eventYear}
                        name="event"
                        options={eventList}
                        groupBy={(option) => option.week}
                        getOptionKey={(option) => option.value}
                        getOptionLabel={(option) => option.label}
                        filterOptions={(options, { inputValue }) => {
                          const filtered = options.filter(
                            (option) =>
                              option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()) ||
                              option.eventCode
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()) ||
                              option.location
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                          );
                          return filtered;
                        }}
                        onChange={handleEventChange}
                        renderInput={(params) => (
                          <TextField {...params} label="Event" />
                        )}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="match"
                      fullWidth
                      label="Match"
                      variant="outlined"
                      type="number"
                      onChange={handleMatchChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth sx={{}}>
                      <InputLabel id="demo-simple-select-label">
                        Colection Style
                      </InputLabel>
                      <Select
                        value={collectionStyle}
                        label="Colection Style"
                        onChange={handleCollectionStyleChangeSelect}
                      >
                        <MenuItem value={"manual"}>Manual</MenuItem>
                        <MenuItem value={"r1"}>Red 1</MenuItem>
                        <MenuItem value={"r2"}>Red 2</MenuItem>
                        <MenuItem value={"r3"}>Red 3</MenuItem>
                        <MenuItem value={"b1"}>Blue 1</MenuItem>
                        <MenuItem value={"b2"}>Blue 2</MenuItem>
                        <MenuItem value={"b3"}>Blue 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      value={robot}
                      name="robot"
                      fullWidth
                      label="Robot #"
                      variant="outlined"
                      type="number"
                      InputLabelProps={{ shrink: robot ? true : undefined }}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRobot(newValue);
                        handleNumberChange(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Div>
          </Grid>
        </Grid>
        <Box>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
