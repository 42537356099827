//Imports
import axios from "axios";

//Blue Alliance
const blueAllianceKey =
  "awpaQWQYzO8lbvawU991yecembSfJzVj9lFK01yxZvVaYJTD6yTnsgl4mhlXXy1E";
const blueAllianceURL = "https://www.thebluealliance.com/api/v3";
//Statbotics
const statboticsKey = "";
const statboticsURL = "";

const firstKey = "";
const firstsURL = "";

//BLUE ALLIANCE--------------------------------------------------------------------------------------------------------------------------------------------------------------------

//Blue Alliance Events by Year
export const fetchEvents = async (year) => {
  const eventAPIURL = `${blueAllianceURL}/events/${year}`;

  try {
    const response = await axios.get(eventAPIURL, {
      headers: { "X-TBA-Auth-Key": blueAllianceKey },
    });

    const events = response.data.map((event) => ({
      label:
        event.short_name === "{}"
          ? "Championship Finals"
          : event.short_name
          ? event.short_name + " - " + event.event_type_string
          : event.name + " - " + event.event_type_string,
      week:
        event.week != null
          ? "Week " + (event.week + 1)
          : event.event_type_string == "Championship Division"
          ? "Championship"
          : event.event_type_string == "Championship Finals"
          ? "Championship"
          : event.event_type_string,
      location: event.location_name || "Unknown",
      startDate: event.start_date,
      eventCode: event.event_code ? event.event_code : "No Event Code",
      value: event.key,
    }));

    const testOption = {
      label: "Test Event - Use for Unofficial Events",
      week: "Testing",
      location: "TestSpot",
      startDate: "Testo'clock",
      eventCode: "test",
      value: "test",
    };

    const sortedEvents = [testOption, ...events].sort((a, b) =>
      a.startDate.localeCompare(b.startDate)
    );

    return sortedEvents;
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};
//Blue Alliance Robot by Event / Match / Robot
export const fetchRobotFromMatch = async (eventCode, match, whichBot) => {
  if (
    whichBot === "manual" ||
    eventCode === "test" ||
    eventCode == null ||
    match == null ||
    match <= 0
  ) {
    return null;
  }

  const robotAPIURL = `${blueAllianceURL}/match/${eventCode}_qm${match}`;

  try {
    const response = await axios.get(robotAPIURL, {
      headers: { "X-TBA-Auth-Key": blueAllianceKey },
    });

    if (response.data?.alliances) {
      const alliance = whichBot.startsWith("r") ? "red" : "blue";
      const position = parseInt(whichBot.charAt(1)) - 1;

      const robotNumber =
        response.data.alliances[alliance]?.team_keys[position];

      if (robotNumber) {
        const cleanRobotNumber = robotNumber.replace("frc", "");
        return {
          event: eventCode,
          robot: cleanRobotNumber,
          match: match,
        };
      }
    }

    return null;
  } catch (error) {
    if (error.response?.status !== 404) {
      console.error("Error fetching match data:", error.message);
    }
    return null;
  }
};

//STATBOTICS-----------------------------------------------------------------------------------------------------------------------------------------------------------------------

//FIRST----------------------------------------------------------------------------------------------------------------------------------------------------------------------------
