import * as React from "react";
import { createContext, useState, useMemo } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { defaultTheme as amplifyDefaultTheme } from "@aws-amplify/ui-react";
import deepmerge from "deepmerge";

export const ThemeContext = createContext();

const themes = {
  //Ignite Insights Dark
  igniteInsightsDark: createTheme(
    deepmerge(createTheme(), {
      palette: {
        mode: "dark",
        primary: {
          main: "#3f0010",
          light: "#3d2b3c",
          dark: "#27032a",
        },
        secondary: {
          main: "#7e0201",
          light: "#deb992 ",
          dark: "#a65d57",
        },
        text: {
          primary: "#ffe580",
          secondary: "#4f102e",
        },
        red: {
          main: "#f44336",
          light: "#fa7c73",
        },
        blue: {
          main: "#29b6f6",
          light: "#5fcafa",
        },
        gray: {
          main: "#333333",
        },
      },
    })
  ),
  reefscape: createTheme(
    deepmerge(createTheme(), {
      palette: {
        mode: "dark",
        primary: {
          main: "#9f3593",
          light: "#b843ac",
          dark: "#8c2e81",
        },
        secondary: {
          main: "#00caac",
          light: "#11edcc",
          dark: "#02ad94",
        },
        text: {
          primary: "#e8f3f4",
          secondary: "#cbf2eb",
        },
        background: {
          default: "#1a2f38",
          paper: "#234750",
        },
        foreground: {
          main: "#234750",
        },
        border: {
          main: "#345a64",
        },
        red: {
          main: "#f44336",
          light: "#fa7c73",
        },
        blue: {
          main: "#00b2ca",
          light: "#33c2d6",
        },
        accent: {
          main: "#ffd93d",
          light: "#ffe06b",
          dark: "#e6c235",
        },
        gray: {
          main: "#333333",
        },
      },
    })
  ),
  crescendo: createTheme(
    deepmerge(createTheme(), {
      palette: {
        mode: "dark",
        primary: {
          main: "#4CAF50",
          light: "#66BB6A",
          dark: "#388E3C",
        },
        secondary: {
          main: "#FF5722",
          light: "#FF7043",
          dark: "#E64A19",
        },
        text: {
          primary: "#FFFFFF",
          secondary: "#B8B8B8",
        },
        background: {
          default: "#121212",
          paper: "#1E1E1E",
        },
        foreground: {
          main: "#282828",
        },
        border: {
          main: "#333333",
        },
        red: {
          main: "#f44336",
          light: "#fa7c73",
        },
        blue: {
          main: "#29b6f6",
          light: "#5fcafa",
        },
        accent: {
          main: "#FF5722",
          light: "#FF7043",
          dark: "#E64A19",
        },
        gray: {
          main: "#333333",
        },
      },
    })
  ),
};

export const ThemeManager = ({ children }) => {
  const [themeName, setThemeName] = useState("reefscape");

  const theme = useMemo(
    () => themes[themeName] || amplifyDefaultTheme[themeName],
    [themeName]
  );

  const toggleTheme = (name) => {
    setThemeName(name);
  };

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
