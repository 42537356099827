import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import CssBaseline from "@mui/material/CssBaseline";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { Amplify } from "aws-amplify";
import { signOut } from "aws-amplify/auth";
import { BrowserRouter } from "react-router-dom";
import config from "./amplifyconfiguration.json";

import { ToastContextProvider } from "./config/toast";
import Dashboard from "./pages/dashboard";

LicenseInfo.setLicenseKey("73342329d11ccf485666f74ff8e0af51Tz03ODAyNSxFPTE3MzA2NjY0ODMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

Amplify.configure(config);

export default function App({ user }) {
	return (
		<BrowserRouter>
			<Authenticator variation="modal">
				<CssBaseline />
				<ToastContextProvider>
					<Dashboard user={user} signOut={signOut} />
				</ToastContextProvider>
			</Authenticator>
		</BrowserRouter>
	);
}
