import { Grid, Button, Box } from "@mui/material";
import * as React from "react";
import { useFormData } from "../../context/FormContext";

export default function ReefscapeForm({ user, signOut }) {
  const { formData, updateFormData } = useFormData();

  return (
    <Box>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              console.log(formData);
            }}
          >
            CRESCENDO
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
