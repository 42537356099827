import React, { useEffect, useState } from "react";
import ImageMapper from "react-img-mapper";
import {
  Grid,
  Button,
  Box,
  Typography,
  styled,
  Stack,
  Paper,
  Divider,
} from "@mui/material";
//Images
import reefscapeField from "../images/reefscapeField.png";
import walle from "../images/walle.png";

const Mapper = ({ parentWidth, image, map }) => {
  const [selectedArea, setSelectedArea] = React.useState({});
  const [areaCounter, setAreaCounter] = React.useState(0);
  const [url, setURL] = React.useState("");
  const [robotCoords, setRobotCoords] = useState({ x: 0, y: 0 });
  const [parentWidths, setParentWidths] = useState(0);

  useEffect(() => {
    setURL(image === "reefscape" ? reefscapeField : reefscapeField);
    setParentWidths(parentWidth);
  });

  const handleClick = (area, event) => {
    console.log(area);
    const img = event.target;
    const rect = img.getBoundingClientRect();
    const x = Math.floor(event.nativeEvent.clientX - rect.left - 30);
    const y = Math.floor(event.nativeEvent.clientY - rect.top - 30);
    console.log(y);
    console.log(x);
    setRobotCoords({ x, y });
  };

  const cancelDefault = (a, b, c) => {
    c?.preventDefault();
    c?.stopPropagation();
    c?.persist();
  };

  return (
    <Box sx={{ position: "relative" }}>
      <ImageMapper
        src={url}
        name="my-map"
        areas={map}
        onClick={(area, index, event) => handleClick(area, event)}
        parentWidth={parentWidths}
        responsive={true}
      />
      <img
        src={walle}
        height={60}
        width={60}
        style={{
          left: robotCoords?.x,
          top: robotCoords?.y,
          position: "absolute",
        }}
      />
    </Box>
  );
};

export default Mapper;
