import {
  Grid,
  Button,
  Box,
  Typography,
  styled,
  Stack,
  Paper,
  Divider,
} from "@mui/material";
import * as React from "react";
import { useFormData } from "../../context/FormContext";
import Mapper from "../../components/generalMapper";
import { useTheme, useMediaQuery } from "@mui/material";
import { useState, useRef, useEffect } from "react";

const Div = styled("div", {
  name: "MuiDiv",
  overridesResolver: (props, styles) => {
    return [styles.root];
  },
})();

export default function ReefscapeForm({ user, signOut }) {
  //Form Data
  const { formData, updateFormData } = useFormData();

  //Start Match Timer
  const [visualTime, setVisualTime] = useState(0);
  const startMatch = async (e) => {
    let timerId = setInterval(countdown, 1000);
    let newVisualTime = 1;
    setVisualTime(newVisualTime);
    function countdown() {
      if (newVisualTime <= 15) {
        newVisualTime = newVisualTime + 1;
      } else if (newVisualTime > 15) {
        newVisualTime = 16;
        clearTimeout(timerId);
      }
      setVisualTime(newVisualTime);
    }
  };

  //AutoMapping
  const [currentLocation, setCurrentLocation] = useState("reef");
  const canvas = useRef();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [autoImageSize, setAutoImageSize] = useState(
    window.innerWidth * (isMobile ? 0.7 : 0.4)
  );
  const updateAutoImageSize = () => {
    setAutoImageSize(window.innerWidth * (isMobile ? 0.7 : 0.4));
  };
  window.addEventListener("resize", updateAutoImageSize);

  const loadedProps = (a, b) => {};
  const REEFSCAPEMAP = [
    {
      id: 1,
      title: "robotPosition",
      shape: "rect",
      name: "robotPosition",
      fillColor: "#bada5555",
      coords: [6, 767, 875, 880],
    },
  ];

  return (
    <Div
      id="foreground"
      variant="outlined"
      sx={{
        borderRadius: 2,
        border: 1,
        borderColor: "border.main",
        backgroundColor: "foreground.main",
        mt: 2,
      }}
    >
      <Box sx={{ display: { xs: "contents", sm: "contents" } }}>
        {/*Computer*/}
        {/*Auto*/}
        <Grid container sx={{ my: 1 }}>
          {/*Title*/}
          <Grid item xs={12} sx={{ justifyItems: "center" }}>
            <Paper
              elevation="0"
              sx={{
                borderRadius: 2,
                padding: 1,
                margin: 1,
                justifyItems: "center",
                width: "40%",
                bgcolor: "background.default",
                mb: 2,
              }}
            >
              <Typography variant="h5" color="secondary.light">
                <b>A U T O</b>
              </Typography>
            </Paper>
          </Grid>
          {/*Map*/}
          <Grid item xs={12} sm={6}>
            <Stack spacing={2} sx={{ alignItems: "center", px: 2, pb: 1 }}>
              {visualTime > 0 && visualTime < 16 && (
                <Box
                  sx={{
                    backgroundColor: "secondary.main",
                    borderRadius: 1,
                    padding: 1,
                    justifyItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography>
                    <b>{visualTime}</b>
                  </Typography>
                </Box>
              )}
              {(visualTime === 0 || visualTime === 16) && (
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => startMatch()}
                >
                  {visualTime === 0 ? "Start" : "Finish"}
                </Button>
              )}
              <Box
                sx={{
                  backgroundColor: "gray.main",
                  borderRadius: 2,
                  padding: 3,
                  justifyItems: "center",
                  width: "100%",
                }}
              >
                <Mapper
                  parentWidth={autoImageSize}
                  image="reefscape"
                  map={REEFSCAPEMAP}
                  containerRef={canvas}
                />
              </Box>
            </Stack>
          </Grid>
          {/*Reef Actions*/}
          {currentLocation.includes("reef") && (
            <Grid
              item
              xs={8}
              sm={3}
              sx={{
                alignContent: "center",
                pb: 1,
              }}
            >
              <Stack
                spacing={2}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  mx: 1,
                  height: "100%",
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Button variant="contained" sx={{ width: "30%" }}>
                    Level 4
                  </Button>
                  <Typography sx={{ width: "20%" }}></Typography>
                  <Button variant="contained" sx={{ width: "30%" }}>
                    Level 4
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Button variant="contained" sx={{ width: "30%" }}>
                    Level 3
                  </Button>
                  <Typography sx={{ width: "20%" }}></Typography>
                  <Button variant="contained" sx={{ width: "30%" }}>
                    Level 3
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Button variant="contained" sx={{ width: "30%" }}>
                    Level 2
                  </Button>
                  <Typography sx={{ width: "20%" }}></Typography>
                  <Button variant="contained" sx={{ width: "30%" }}>
                    Level 2
                  </Button>
                </Stack>
                <Button fullWidth variant="contained" sx={{ height: "100%" }}>
                  Level 1
                </Button>
                <Button
                  fullWidth
                  color="red"
                  variant="contained"
                  sx={{
                    height: "100%",
                  }}
                >
                  Missed Coral
                </Button>
              </Stack>
            </Grid>
          )}
          {currentLocation.includes("reef") && (
            <Grid item xs={4} sm={3} sx={{ alignContent: "center", pb: 1 }}>
              <Stack
                spacing={2}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  ml: 1,
                  mr: 2,
                  height: "100%",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  sx={{ height: "100%" }}
                >
                  Picked Off Algae
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  sx={{
                    height: "100%",
                  }}
                >
                  Hit Off Algae
                </Button>
              </Stack>
            </Grid>
          )}
          {/*Pick Up Actions*/}
          {currentLocation.includes("pickup") && (
            <Grid item xs={12} sm={6} sx={{ alignContent: "center" }}>
              <Stack
                spacing={2}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{
                      height: "100%",
                    }}
                  >
                    Picked Up Algae
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="blue"
                    sx={{
                      height: "100%",
                    }}
                  >
                    Picked Up Both
                  </Button>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "100%",
                    }}
                  >
                    Picked Up Coral
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="red"
                    sx={{
                      height: "100%",
                    }}
                  >
                    Missed
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          )}
          {/*Algae Actions*/}
          {currentLocation.includes("algae") && (
            <Grid item xs={12} sm={6} sx={{ alignContent: "center" }}>
              <Stack
                spacing={2}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{
                      height: "100%",
                    }}
                  >
                    Scored Net
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="red"
                    sx={{
                      height: "100%",
                    }}
                  >
                    Missed Net
                  </Button>
                </Stack>
                <Box
                  sx={{
                    borderRadius: 1,
                    backgroundColor: "primary.light",
                    width: "95%",
                    height: "3%",
                  }}
                ></Box>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{
                      height: "100%",
                    }}
                  >
                    Scored Processor
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="red"
                    sx={{
                      height: "100%",
                    }}
                  >
                    Missed Processor
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box sx={{ display: { xs: "none", sm: "none" } }}></Box>
    </Div>
  );
}
